import { HTTP, HTTP2 } from "../../services/axios";

export default {
  state: {
    models: [],
    modelById: "",
    reviewsById: null,
    makingAPIcalls: true,
    makingPaymentAPIcalls: false,
    locations: "",
    modelWiseLocation: "",
    referalPoints: 0,
    coupons: "",
    globalLoadRent: false,
    reviewsStatsById: null,
    timeSlots: null,
    serviceTypes: null,
  },
  getters: {
    getReviewsStatsById: (state) => state.reviewsStatsById,
    globalLoadRent(state) {
      return state.globalLoadRent;
    },
    makingAPIcalls(state) {
      return state.makingAPIcalls;
    },
    makingPaymentAPIcalls(state) {
      return state.makingPaymentAPIcalls;
    },
    getLocations(state) {
      return state.locations;
    },
    getModelWiseLocation(state) {
      return state.modelWiseLocation;
    },
    get_reviews_by_id(state) {
      return state.reviewsById;
    },
    getModel(state) {
      return state.models;
    },
    getCouponsX(state) {
      return state.coupons;
    },
    getReferalPoints(state) {
      return state.referalPoints;
    },
    modelById(state) {
      return state.modelById;
    },
    getTimeSlots(state) {
      return state.timeSlots;
    },
    serviceTypes(state) {
      return state.serviceTypes;
    },
  },
  mutations: {
    SET_LOADING: (state, value) => {
      state.makingAPIcalls = value;
    },
    SET_GLOBAL_LOAD_RENT: (state, value) => {
      state.globalLoadRent = value;
    },
    SET_LOADING_PAYMENT: (state, value) => {
      state.makingPaymentAPIcalls = value;
    },
    GET_LOCATIONS: (state, value) => {
      state.locations = value;
    },
    GET_TIMESLOTS: (state, value) => {
      state.timeSlots = value;
    },
    GET_SERVICE_TYPES: (state, value) => {
      state.serviceTypes = value;
    },
    GET_REFERAL_POINTS: (state, value) => {
      state.referalPoints = value;
    },
    MODEL_WISE_LOCATION: (state, value) => {
      state.modelWiseLocation = value;
    },
    GET_MODELS: (state, value) => {
      state.models = value;
    },
    GET_COUPONS: (state, value) => {
      state.coupons = value;
    },
    GET_MODEL_BY_ID: (state, value) => {
      state.modelById = value;
    },
    getReviewById: (state, value) => {
      state.reviewsById = value;
    },
    getReviewStatsById: (state, value) => {
      state.reviewsStatsById = value;
    },
  },
  actions: {
    // async initializeModelFetch ({ dispatch }, payload) {
    //   dispatch('get_model_by_id', { id: payload.id })
    //   dispatch('get_reviews_by_id', { id: payload.id })
    // },
    async initializeModelDisplay({ dispatch }, payload) {
      dispatch("get_model_by_id", { id: payload.id });
      dispatch("get_reviews_stats_by_id", { id: payload.id });
    },
    getReferalPoints({ commit, dispatch }, payload) {
      var dispatchValue = payload.dispatch;
      commit("SET_LOADING", true);
      HTTP.post("getReferalPoints", {
        id: payload.id,
      })
        .then((response) => {
          commit("GET_REFERAL_POINTS", response.data);
          commit("SET_LOADING", false);
          if (dispatch) {
            dispatch(dispatchValue);
          }
        })
        .catch(() => {});
    },
    get_locations({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        HTTP2.get("/locations")
          .then((response) => {
            commit("GET_LOCATIONS", response.data);
            resolve(response.data);
            commit("SET_LOADING", false);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    getTimeSlots({ commit }, payload) {
      commit("SET_LOADING", true);
      HTTP.post("/timeslot/app", {
        date: payload.date,
        type: payload.type,
      })
        .then((response) => {
          commit("GET_TIMESLOTS", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {
          commit("SET_LOADING", false);
        });
    },
    getServiceTypes({ commit }) {
      commit("SET_LOADING", true);
      HTTP.get("services/job?status=1")
        .then((response) => {
          commit("GET_SERVICE_TYPES", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {
          commit("SET_LOADING", false);
        });
    },
    get_coupons({ commit }, payload) {
      commit("SET_LOADING", true);
      var query = {};
      if (payload.type) {
        query.type = payload.type;
      }
      HTTP.get(`model/${payload.id}/coupons?type=new`, {
        params: query,
      })
        .then((response) => {
          commit("GET_COUPONS", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {
          commit("SET_LOADING", false);
        });
    },
    verifyCoupon({ commit }, payload) {
      commit("SET_LOADING_PAYMENT", true);
      return new Promise((resolve, reject) => {
        HTTP.post("coupon/verify", payload.obj)
          .then((response) => {
            commit("SET_LOADING_PAYMENT", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING_PAYMENT", false);
            reject(e);
          });
      });
    },
    model_wise_location({ commit }, payload) {
      commit("SET_LOADING", true);
      HTTP.post("modelWiseLocation", payload)
        .then((response) => {
          commit("MODEL_WISE_LOCATION", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {
          commit("SET_LOADING", false);
        });
    },
    get_models({ commit }) {
      // var dispatchValue = payload.dispatch;
      commit("SET_LOADING", true);
      HTTP.get("model")
        .then((response) => {
          commit("GET_MODELS", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {});
    },
    get_model_by_id({ commit }, payload) {
      commit("SET_LOADING", true);
      HTTP.get(`model/${payload.id}`)
        .then((response) => {
          commit("GET_MODEL_BY_ID", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {});
    },
    joinWaitlist({ commit }, payload) {
      // var customerId = this.getters.userData.id
      commit("SET_GLOBAL_LOAD_RENT", true);
      return new Promise((resolve, reject) => {
        HTTP.post("/customer/joinWaitlist", payload)
          .then((response) => {
            commit("SET_GLOBAL_LOAD_RENT", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_GLOBAL_LOAD_RENT", false);
            reject(e);
          });
      });
    },
    getBookingExtendInfo({ commit }, payload) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.post("/booking/extend/verify/payment", {
          paymentId: payload.paymentId,
        })
          .then((response) => {
            commit("SET_LOADING", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    get_reviews_by_id({ commit }, payload) {
      commit("SET_LOADING", true);
      HTTP.get(`model/${payload.id}/reviews`)
        .then((response) => {
          commit("getReviewById", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {
          commit("getReviewById", null);
        });
    },
    get_reviews_stats_by_id({ commit }, payload) {
      commit("SET_LOADING", true);
      HTTP.get(`model/${payload.id}/reviews/stats`)
        .then((response) => {
          commit("getReviewStatsById", response.data);
          commit("SET_LOADING", false);
        })
        .catch(() => {
          commit("getReviewStatsById", null);
        });
    },
    like_reviews_by_id({ commit }, payload) {
      // commit('SET_LOADING', true)
      // var vehicle = payload.vehicle
      var id = payload.id;
      var like = payload.like;
      delete payload.vehicle;
      return new Promise((resolve, reject) => {
        HTTP.post(`review/${id}/like/${like}`, {})
          .then((response) => {
            // commit('SET_LOADING', false)
            // dispatch('get_reviews_by_id', { id: vehicle })
            resolve(response);
          })
          .catch((e) => {
            commit("AUTH_LOADING", false);
            reject(e);
          });
      });
    },
    add_reviews_by_id({ commit, dispatch }, payload) {
      // commit('SET_LOADING', true)
      var id = payload.id;
      delete payload.id;
      return new Promise((resolve, reject) => {
        HTTP.post(`model/${id}/reviews`, payload)
          .then((response) => {
            // commit('SET_LOADING', false)
            dispatch("get_reviews_by_id", { id: id });
            resolve(response);
          })
          .catch((e) => {
            commit("AUTH_LOADING", false);
            reject(e);
          });
      });
    },
    generateOrder({ commit }, payload) {
      commit("SET_LOADING_PAYMENT", true);
      return new Promise((resolve, reject) => {
        HTTP.post("/booking/order", payload.obj)
          .then((response) => {
            commit("SET_LOADING_PAYMENT", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING_PAYMENT", false);
            reject(e);
          });
      });
    },
    verifyPayment({ commit }, payload) {
      var bookingId = payload.bookingId;
      commit("SET_LOADING_PAYMENT", true);
      return new Promise((resolve, reject) => {
        HTTP.post("booking/payment/" + bookingId + "/verify", payload.obj)
          .then((response) => {
            commit("SET_LOADING_PAYMENT", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING_PAYMENT", false);
            reject(e);
          });
      });
    },
  },
};
