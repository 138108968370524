// Import the Firebase application and analytics services
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent as firebaseLogEvent,
  setUserProperties,
} from "firebase/analytics";

// Firebase config as previously defined
const firebaseConfig = {
  apiKey: "AIzaSyAmhngP_gcotcy9dTxjXZKtyKxU5ngrGSc",
  authDomain: "book-ontrack.firebaseapp.com",
  projectId: "book-ontrack",
  storageBucket: "book-ontrack.appspot.com",
  messagingSenderId: "177685112969",
  appId: "1:177685112969:web:4c2e130e0ab58c9f7b7e58",
  measurementId: "G-N4RDDG5SE6",
};

/// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get Firebase Analytics instance
const analytics = getAnalytics(app);

// Function to log custom events using Firebase Analytics
const logFirebaseEvent = (eventName, params) => {
  // Retrieve traffic source details from local storage
  const source = localStorage.getItem("trafficSource");
  const medium = localStorage.getItem("trafficSourceMedium");
  const campaign = localStorage.getItem("trafficSourceCampaign");

  // Set user properties for source, medium, and campaign if available
  if (source) setUserProperties(analytics, { user_source: source });
  if (medium) setUserProperties(analytics, { user_medium: medium });
  if (campaign) setUserProperties(analytics, { user_campaign: campaign });

  // Log the event with the additional parameters
  let eventParams = {
    source: source || "unknown",
    medium: medium || "unknown",
    campaign: campaign || "unknown",
    ...params,
  };

  console.log("🚀 ~ logEvent ~ eventParams:", eventParams);
  firebaseLogEvent(analytics, eventName, eventParams);
};

export { logFirebaseEvent };
