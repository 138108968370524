import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4D43D8",
        primary_two: "#DD827E",
        gradient:
          "linear-gradient(-90deg,rgba(142, 35, 215, 1) 7%,rgba(79, 67, 216, 1) 91%)",
      },
    },
  },
});
