import { HTTP, HTTPV1, test } from "@/services/axios";
import store from "../index";
export default {
  state: {
    OtPoints: 0,
    makingCustomerAPIcalls: true,
    ongoingBooking: "",
    customerDetails: null,
    notifications: null,
    bookingByQuery: null,
    ratingBookingId: "",
    myBikes: "",
    coupons: "",
    allCoupons: "",
    externalCoupons: "",
    externalCouponsAvailed: "",
  },
  getters: {
    makingCustomerAPIcalls(state) {
      return state.makingCustomerAPIcalls;
    },
    getCoupons(state) {
      return state.coupons;
    },
    getExternalCoupons(state) {
      return state.externalCoupons;
    },
    getExternalCouponsAvailed(state) {
      return state.externalCouponsAvailed;
    },
    getAllCoupons(state) {
      return state.allCoupons;
    },
    getOtPoints(state) {
      return state.OtPoints;
    },
    localCustomerDetails() {
      return store.getters.getLoggedInUser;
    },
    customerDetails(state) {
      return state.customerDetails;
    },
    getNotifications(state) {
      return state.notifications;
    },
    getBookingByQuery(state) {
      return state.bookingByQuery;
    },
    getMyBikes(state) {
      return state.myBikes;
    },
  },
  mutations: {
    SET_LOADING: (state, value) => {
      state.makingCustomerAPIcalls = value;
    },
    getOtPoints: (state, value) => {
      state.OtPoints = value;
    },
    GET_BOOKING_BY_QUERY: (state, value) => {
      state.bookingByQuery = value;
    },
    get_customer_detail: (state, value) => {
      state.customerDetails = value;
    },
    GET_NOTIFICATIONS: (state, value) => {
      state.notifications = value;
    },
    GET_MY_BIKES: (state, value) => {
      state.myBikes = value;
    },
    GET_COUPONS: (state, value) => {
      state.coupons = value;
    },
    GET_EXT_COUPONS: (state, value) => {
      state.externalCoupons = value;
    },
    GET_EXT_COUPONS_AVAILED: (state, value) => {
      state.externalCouponsAvailed = value;
    },
    GET_ALL_COUPONS: (state, value) => {
      state.allCoupons = value;
    },
    RESET_CUSTOMER: (state) => {
      state.customerDetails = null;
      state.OtPoints = 0;
    },
  },
  actions: {
    async initializeCustomer({ dispatch }) {
      dispatch("getCustomerDetails");
    },
    resetCustomer({ commit }) {
      commit("RESET_CUSTOMER");
    },
    getMyBikes({ commit }) {
      // var customerId = this.getters.userData.id
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("myvehicle/fetch/")
          .then((response) => {
            commit("SET_LOADING", false);
            commit("GET_MY_BIKES", response.data);
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    getAllCoupons({ commit }, payload) {
      // var customerId = this.getters.userData.id
      let query = {};
      if (payload) {
        query.id = payload.id;
      }
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("coupon/fetch/getPremiumCoupons", {
          params: query,
        })
          .then((response) => {
            commit("SET_LOADING", false);
            commit("GET_ALL_COUPONS", response.data);
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    getCoupons({ commit }, payload) {
      // var customerId = this.getters.userData.id
      let query = {};
      if (payload) {
        query.id = payload.id;
      }
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("coupon/fetch/getPremiumCoupons/purchased", {
          params: query,
        })
          .then((response) => {
            commit("SET_LOADING", false);
            commit("GET_COUPONS", response.data);
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    getExternalCoupons({ commit }, payload) {
      let query = {};
      if (payload) {
        query.id = payload.id;
      }
      // var customerId = this.getters.userData.id
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("coupon/fetch/external", {
          params: query,
        })
          .then((response) => {
            commit("SET_LOADING", false);
            commit("GET_EXT_COUPONS", response.data);
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    getExternalCouponsAvailed({ commit }, payload) {
      let query = {};
      if (payload) {
        query.id = payload.id;
      }
      // var customerId = this.getters.userData.id
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("coupon/fetch/external/availed", {
          params: query,
        })
          .then((response) => {
            commit("SET_LOADING", false);
            commit("GET_EXT_COUPONS_AVAILED", response.data);
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    changeAvatar({ commit }, payload) {
      // var customerId = this.getters.userData.id
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.post("profile/user/details/avatar", payload)
          .then((response) => {
            commit("SET_LOADING", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    saveProfile({ commit }, payload) {
      // var customerId = this.getters.userData.id
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.post("saveProfile", payload)
          .then((response) => {
            commit("SET_LOADING", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    updateCustomer({ commit }, payload) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.post("customer/update", payload)
          .then((response) => {
            commit("SET_LOADING", false);
            // store.dispatch("updateCustomerProfile", { user: response.data });
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    getCustomerDetails({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        try {
          if (localStorage.getItem("user-data")) {
            test.post("/customer/info").then((data) => {
              commit("get_customer_detail", data.data);
              store.dispatch("updateCustomerProfile", { user: data.data });
              resolve(data);
            });
          } else {
            resolve();
          }
        } catch {
          reject();
        }
      });
    },
    getOtPoints({ commit }) {
      commit("SET_LOADING", true);
      HTTP.get("profile/user/otpoints")
        .then((response) => {
          commit(
            "getOtPoints",
            response.data.data ? Math.floor(response.data.data) : 0
          );
          commit("SET_LOADING", false);
        })
        .catch((e) => {
          commit("SET_LOADING", false);
          this.errors.push(e);
        });
    },
    createService({ commit }, payload) {
      payload.customer = this.getters.localCustomerDetails.id;
      return new Promise((resolve, reject) => {
        HTTP.post("/services", payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YWRtaW5BdXRoOnZpc2h1QDE5OTI=",
          },
        })
          .then((response) => {
            commit("LOADING_API", false);
            resolve(response.data);
          })
          .catch((e) => {
            commit("LOADING_API", false);
            reject(e);
          });
      });
    },
    getBookingByQuery({ commit }, payload) {
      // payload.customer = this.getters.localCustomerDetails.id
      // payload.paymentStatus = 1
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("customer/bookings", payload)
          .then((response) => {
            commit("GET_BOOKING_BY_QUERY", response.data);
            resolve(response.data[0]);
            commit("SET_LOADING", false);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            this.errors.push(e);
            reject(e);
          });
      });
    },
    checkReputeStatus({ commit }) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("profile/user/repute")
          .then((response) => {
            commit("SET_LOADING", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
    onBoardRepute({ commit }) {
      commit("SET_LOADING_PAYMENT", true);
      return new Promise((resolve, reject) => {
        HTTP.post("repute/board", {})
          .then((response) => {
            commit("SET_LOADING_PAYMENT", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING_PAYMENT", false);
            reject(e);
          });
      });
    },
    getNotifications({ commit }) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.get("/customer/notification")
          .then((response) => {
            commit("GET_NOTIFICATIONS", response.data);
            commit("SET_LOADING", false);
            resolve(response.data);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            this.errors.push(e);
            reject(e);
          });
      });
    },
    uploadDocument({ commit }, payload) {
      var key = payload.key;
      var fd = new FormData();
      fd.append("image", payload.image);
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTPV1.post(
          "uploadCustomerDoc?id=" +
            payload.customerId +
            "&key=" +
            key +
            "&old=" +
            payload.old,
          fd
        )
          .then((response) => {
            commit("SET_LOADING", false);
            // dispatch('get_reviews_by_id', { id: id })
            resolve(response);
          })
          .catch((e) => {
            commit("SET_LOADING", false);
            reject(e);
          });
      });
    },
  },
};
