import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import rent from "./modules/rent";
import customer from "./modules/customer";
import finance from "./modules/finance"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    httpLoading: false,
    notificationReceived: true,
    notificationServiceAvailable: true,
    bottomSheet: false,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: auth,
    rent: rent,
    customer: customer,
    finance: finance,
  },
});
