import Vue from "vue";
import VueRouter from "vue-router";
import routesData from "./routes";
Vue.use(VueRouter);
import VueAnalytics from "vue-analytics";
var routes = routesData;
//google analytics ID
Vue.use(VueAnalytics, {
  id: "UA-112981637-3",
  routes,
});

var router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
