export default [
  {
    path: "/",
    redirect: "two-wheeler-rental/models",
    name: "Home",
  },
  {
    path: "/two-wheeler-services",
    component: () => import("../screens/HomePage"),
    name: "Home",
    meta: { root: true },
  },
  {
    path: "/two-wheeler-services/blogs",
    component: () => import("../screens/TwlServices/BlogsPage"),
    meta: { product: "services" },
    name: "Two wheeler Blog",
  },
  {
    path: "/two-wheeler-services/blogs/:id",
    component: () => import("../screens/TwlServices/ViewBlogsPage"),
    meta: { product: "services" },
    name: "Two wheeler Blog View",
  },
  {
    path: "/two-wheeler-rental",
    component: () => import("../LandingPages/RentalsLanding"),
    name: "Two wheeler rentals landing",
  },
  {
    path: "/two-wheeler-loan",
    component: () => import("../LandingPages/FinanceLanding"),
    meta: { product: "finance" },
    name: "Two wheeler Loan Landing",
  },
  {
    path: "/two-wheeler-loan-against-vehicle",
    component: () => import("../LandingPages/LoanAgainstVehicle"),
    meta: { product: "finance" },
    name: "Two wheeler Loan Against Vehicle",
  },
  {
    path: "/two-wheeler-loan-against-vehicle/faq",
    component: () => import("../screens/LoanAgainstVehicle/FaqSection"),
    meta: { product: "finance" },
    name: "Two wheeler Loan Against FAQ",
  },
  {
    path: "/two-wheeler-used-bike",
    component: () => import("../LandingPages/UsedBikeLanding"),
    meta: { product: "finance" },
    name: "Used Bike Homepage",
  },
  {
    path: "/two-wheeler-used-bike/bikes",
    component: () => import("../screens/usedBike/usedBikeHome"),
    meta: { product: "finance" },
    name: "Used Bikes Selecr",
  },
  {
    path: "/two-wheeler-loan/:id/form",
    component: () => import("../screens/finance/FinanceForm"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/take-action/:action",
    component: () => import("../screens/finance/loanActionForm"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/bajaj/offer/:id",
    component: () => import("../screens/finance/bajajOffer"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/form/bajaj",
    component: () => import("../screens/finance/financeFormBajaj"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/bajaj/application_completed",
    component: () => import("../screens/finance/applicationCompletionPage.vue"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/form/creditOffer/bajaj/:id",
    component: () => import("../screens/finance/financeFormCreditForm"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/form/appScore/bajaj/:id",
    component: () => import("../screens/finance/financeFormAppscoreOffer"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/helpers/createloan/bulk",
    component: () => import("../screens/finance/helperPage/createLoanBulk"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/form/update-bulk",
    component: () => import("../screens/finance/helperPage/updateLoanBulk.vue"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/form/update/:id",
    component: () => import("../screens/finance/FinanceFormUpdate"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/start/loan-form",
    component: () => import("../screens/finance/Forms/StandardForm.vue"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/status/:id",
    component: () =>
      import("../screens/finance/RedirectPages/ApplicationStatus"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/schedule/:id",
    component: () => import("../screens/finance/RedirectPages/ConfirmLoan"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/cancel/:id",
    component: () => import("../screens/finance/RedirectPages/CancelLoan"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/bikes/:makeId",
    component: () => import("../screens/finance/FinanceBikeSelection"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/bikes/:makeId/admin",
    component: () => import("../screens/finance/Admins/FinanceBikeSelection"),
    meta: { product: "finance" },
  },

  {
    path: "/two-wheeler-loan/select-make",
    component: () => import("../screens/finance/Forms/StandardForm.vue"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/credit-appscore/:id",
    component: () => import("../screens/finance/bajajFormCreditAppscore.vue"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/select-make-v2",
    component: () => import("../screens/finance/FinanceBikeSelection.vue"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/bikes/:makeId/:id",
    component: () => import("../screens/finance/FinanceDetails"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/bikes/:makeId/:id/admin",
    component: () => import("../screens/finance/Admins/FinanceDetailsAdmin"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/bikes/:makeId/:id/UAT",
    component: () => import("../screens/finance/Admins/FinanceDetailsUAT"),
    meta: { product: "finance" },
  },
  {
    path: "/two-wheeler-loan/create-loan",
    component: () => import("../screens/finance/financeLoanCreate"),
  },
  {
    path: "*",
    component: () => import("../screens/redirectPages/404Error"),
  },
  {
    path: "/login",
    component: () => import("../screens/auth/LoginPage"),
  },
  {
    path: "/address-form",
    component: () => import("../components/AddressForm"),
  },
  {
    path: "/complaint-form",
    component: () => import("../components/GrievancePage"),
  },

  {
    path: "/account",
    beforeEnter: authGuard,
    component: () => import("../screens/profile/ProfileHome"),
    children: [
      {
        path: "",
        component: () => import("../screens/profile/child/EditProfile"),
        meta: { showProfileRoute: true },
      },
      {
        path: "profile",
        component: () => import("../screens/profile/child/EditProfile"),
      },
      {
        path: "documents",
        component: () => import("../screens/profile/child/UserDocuments"),
      },
      {
        path: "address-book",
        component: () => import("../screens/profile/child/AddressList"),
      },
      {
        path: "refer-and-earn",
        component: () => import("../screens/profile/child/ReferEarn"),
      },
      {
        path: "points-and-penalties",
        component: () => import("../screens/profile/child/PointsAndPenalties"),
      },
      {
        path: "help-and-support",
        component: () => import("../screens/profile/child/HelpAndSupport"),
      },
      {
        path: "bookings/extend/:id",
        component: () =>
          import("../screens/profile/child/bookings/extendBooking"),
      },
      {
        path: "bookings",
        component: () => import("../screens/profile/child/BookingsList"),
        meta: { showProfileRoute: true },
        children: [
          {
            path: "",
            component: () =>
              import("../screens/profile/child/BookingsListCurrent"),
          },
          {
            path: "completed",
            component: () =>
              import("../screens/profile/child/BookingsListCompleted"),
          },
        ],
      },
    ],
  },

  {
    path: "/feedback/:bookingId",
    component: () => import("../screens/misc/CustomerFeedback"),
  },
  {
    path: "/feedback/agent/:incidentId",
    component: () => import("../screens/misc/AgentCallFeedback"),
  },
  {
    path: "/feedback-submit/:otp",
    component: () => import("../screens/misc/FeedbackSubmit"),
  },
  {
    path: "/booking-redirect",
    component: () => import("../screens/redirectPages/FailedBooking"),
  },
  {
    path: "/success-booking",
    component: () => import("../screens/redirectPages/SuccessBooking"),
  },

  {
    path: "/help-and-support",
    component: () => import("../screens/help&support/HelpAndSupportHome"),
    children: [
      {
        path: "",
        component: () => import("../screens/help&support/FaqS/FaqList"),
        meta: { showHelpNavs: true },
      },
      {
        path: "terms-and-conditions",
        component: () => import("../screens/help&support/TermsAndConditions"),
      },
      {
        path: "privacy-policy",
        component: () => import("../screens/help&support/PrivacyPolicy"),
      },
      {
        path: "disclaimer-policy",
        component: () => import("../screens/help&support/DisclaimerPolicy"),
      },
      {
        path: "copyright-guidelines",
        component: () => import("../screens/help&support/CopyrightGuidelines"),
      },
      {
        path: "frequently-asked-questions",
        component: () => import("../screens/help&support/FaqS/RootFile"),
        children: [
          {
            path: "",
            component: () => import("../screens/help&support/FaqS/FaqList"),
          },
          {
            path: ":id",
            component: () => import("../screens/help&support/FaqS/FaqRentals"),
          },
        ],
      },
    ],
  },
  {
    path: "/two-wheeler-rental/models",
    component: () => import("../screens/models/ModelList"),
  },
  {
    path: "/two-wheeler-rental/how-it-works",
    component: () => import("../screens/pages/HowItWorks"),
  },
  {
    path: "/model-display/:id/:link",
    component: () => import("../screens/models/ModelDetails"),
  },
  {
    path: "/checkouts/:bookingId/payment-methods",
    component: () => import("../screens/pages/checkout/PaymentMethods"),
  },
  {
    name: "Verify Extension",
    path: "/checkouts/extension/:extendId/verify-payment",
    component: () =>
      import("../screens/pages/checkout/PaymentVerifyExtension.vue"),
  },
  {
    path: "/checkouts/:bookingId/payment-success",
    component: () => import("../screens/pages/checkout/PaymentSuccess"),
  },

  {
    path: "/account/notifications",
    name: "Account Notification",
    component: () => import("../screens/views/NotificationView"),
  },
  {
    path: "/bookings/extend/:id",
    component: () => import("../screens/profile/child/bookings/extendBooking"),
  },
];

function authGuard(to, from, next) {
  const token = localStorage.getItem("user-token");
  if (token) {
    next();
  } else {
    next({ path: "/login", query: { next: to.fullPath } });
  }
}
