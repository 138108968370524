import { HTTP, HTTP2, test } from "../../services/axios";
import moment from "moment";
export default {
  state: {
    loading: false,
    loggedInUser: null,
    token: null,
  },
  getters: {
    getLoggedInUser(state) {
      return state.loggedInUser;
    },
    token: (state) => state.token,
  },
  actions: {
    updateCustomerProfile({ commit }, payload) {
      commit("UPDATE_CUSTOMER_AUTH", payload);
    },
    async initializeSession({ commit }) {
      return new Promise((resolve, reject) => {
        if (!localStorage.getItem("user-data")) {
          return resolve();
        }
        try {
          const userData = localStorage.getItem("user-data");
          const userToken = localStorage.getItem("user-token");
          const sessionCreated = localStorage.getItem("session-created");
          const sessionCreatedDays = moment(Date.now()).diff(
            Number(sessionCreated),
            "days"
          );
          if (sessionCreatedDays > 15) {
            commit("AUTH_LOGOUT");
          } else {
            commit("UPDATE_CUSTOMER_AUTH", {
              user: JSON.parse(userData),
              token: userToken,
            });
            resolve();
          }
        } catch (err) {
          reject();
        }
      });
    },
    VERIFY_PHONE: ({ commit }, payload) => {
      commit("AUTH_LOADING", true);
      const secret = generateDynamicSecret();
      return new Promise((resolve, reject) => {
        HTTP.post("profile/v2/website/service/send-otp", payload, {
          headers: {
            "x-shared-secret": secret,
          },
        })
          // server.post('profile/user/try', payload)
          .then((resp) => {
            commit("AUTH_LOADING", false);
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_LOADING", false);
            reject(err);
          });
      });
    },
    AUTH_CHECK: ({ commit }, payload) => {
      commit("AUTH_LOADING", true);
      return new Promise((resolve, reject) => {
        HTTP.post("profile/v1/user/verify/otp", payload)
          .then((resp) => {
            commit("UPDATE_CUSTOMER_AUTH", {
              user: resp.data.data,
              token: resp.data.data.token,
            });
            resolve(resp);
          })
          .catch((err) => {
            commit("AUTH_LOADING", false);
            reject(err);
          });
      });
    },
    AUTH_LOGOUT: ({ commit }) => {
      return new Promise((resolve) => {
        commit("END_SESSION");

        resolve();
      });
    },
  },
  mutations: {
    UPDATE_CUSTOMER_AUTH: (state, data) => {
      if (data.user) {
        localStorage.setItem("user-data", JSON.stringify(data.user));
        state.loggedInUser = data.user;
      }
      console.log(data.token);
      if (data.token) {
        localStorage.setItem("user-token", data.token);
        localStorage.setItem("session-created", JSON.stringify(Date.now()));
        HTTP.defaults.headers.Authorization = "Bearer " + data.token;
        HTTP2.defaults.headers.Authorization = "Bearer " + data.token;
        test.defaults.headers.Authorization = "Bearer " + data.token;
        state.token = data.token;
      }
    },
    END_SESSION: (state) => {
      HTTP.defaults.headers.Authorization = "";
      HTTP2.defaults.headers.Authorization = "";
      test.defaults.headers.Authorization = "";
      state.loggedInUser = "";
      state.token = "";
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-data");
      localStorage.removeItem("session-created");
    },
  },
};
const VUE_APP_API_KEY = "ONTWS030605382024";
function generateDynamicSecret() {
  const timestamp = moment().unix(); // Current timestamp in seconds
  const alphabet = String.fromCharCode(65 + Math.floor(Math.random() * 26)); // Random alphabet between A-Z
  const randomNumber = Math.floor(Math.random() * 5) + 1; // Random number between 1-5
  const randomNum = Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000; // Random number between 1000-3000
  return `${timestamp}${alphabet}${randomNumber}${VUE_APP_API_KEY}${randomNum}`;
}
