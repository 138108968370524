import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueRouter from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import router from "./router";
import Notifications from "vue-notification";
import Toasted from "vue-toasted";
import VueI18n from "vue-i18n";
import VueMask from "v-mask";
import { VueReCaptcha } from "vue-recaptcha-v3";

// For more options see below
Vue.use(VueReCaptcha, { siteKey: "6LfHfhsqAAAAAKjabCxa3M2AGyrDmRO_qXoLYvhY" });
Vue.use(VueMask);
import VueMeta from "vue-meta";

Vue.use(VueMeta);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  messages: {
    en: require("./locales/en.json"),
    hi: require("./locales/hi.json"),
    ka: require("./locales/ka.json"),
  },
});
import * as firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAmhngP_gcotcy9dTxjXZKtyKxU5ngrGSc",
  authDomain: "book-ontrack.firebaseapp.com",
  projectId: "book-ontrack",
  storageBucket: "book-ontrack.appspot.com",
  messagingSenderId: "177685112969",
  appId: "1:177685112969:web:4c2e130e0ab58c9f7b7e58",
  measurementId: "G-N4RDDG5SE6",
};
firebase.initializeApp(firebaseConfig);

Vue.use(Toasted, {
  duration: 1500,
});

Vue.use(Notifications);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(require("vue-moment"));
Vue.filter("currency", function (value) {
  return "₹" + parseFloat(value).toLocaleString();
});
if (localStorage.getItem("user-data")) store.dispatch("initializeSession");

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
