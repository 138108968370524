import router from "@/router";
import store from "@/store";
import axios from "axios";
var baseDomain = "https://api.on-track.in";
let baseDomainv2;

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  // Code to be executed when the environment is localhost
  baseDomainv2 = "http://localhost:3000";
  console.log("Running in localhost environment");
} else {
  baseDomainv2 = "https://glacier.on-track.in";
  // Code for other environments (e.g., production)
  console.log("Running in production environment");
}

var baseURL = `${baseDomain}/api`;
var baseURLv2 = `${baseDomainv2}/api`;

export var HTTP = axios.create({
  baseURL: baseURLv2,
});
export var HTTP2 = axios.create({
  baseURL: baseURLv2,
});
export var HTTPV1 = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: "Basic YWRtaW5BdXRoOnZpc2h1QDE5OTI=",
  },
});
export var server = axios.create({
  baseURL: "http://159.89.164.225:45698/api/",
});
// export var test = axios.create({
//   baseURL: baseURLv2,
//   headers: {
//     Authorization: "Bearer " + localStorage.getItem("user-token"),
//   },
// });
export var test = axios.create({
  baseURL: baseURLv2,
});

// Add an Axios interceptor to handle 401 unauthorized responses
HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Unauthorized, redirect to the login page
      localStorage.removeItem("agent_id");
      localStorage.removeItem("agentToken");
      localStorage.removeItem("userData");
      console.log(router);
      store.dispatch("AUTH_LOGOUT");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);
